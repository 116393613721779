import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  UncontrolledPopover,
  Button,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Form,
  Input,
  Label,
} from 'reactstrap'
import { createSchedule } from 'services/doctor'
import { alertSuccess, alertWarning } from 'utils/toast'
import { OverlayComponent, OverlayContainer } from './styles'

interface ScheduleProps {
  uuid: string
  day: string
  startTime: string
  endTime: string
  specialty: string
  // eslint-disable-next-line no-unused-vars
  handleDelete: (uuid: string) => void
  newSchedule: any
  // eslint-disable-next-line no-unused-vars
  setNewSchedule: (newSchedule: any) => void
  schedules: any
  // eslint-disable-next-line no-unused-vars
  setSchedules: (schedules: any) => void
  // eslint-disable-next-line no-unused-vars
  setLoading: (loading: boolean) => void
  specialties: any[]
  groups: any[]
  info: any
}

const Overlay = ({
  specialty,
  day,
  uuid,
  startTime,
  endTime,
  handleDelete,
  newSchedule,
  setNewSchedule,
  info,
  specialties,
  schedules,
  setLoading,
  groups,
  setSchedules,
}: ScheduleProps) => {
  const [modal, setModal] = useState(false)
  const [modalSchedule, setModalSchedule] = useState(false)
  const [attendanceFormat, setAttendanceFormat] = useState('Padrão')
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])
  const defaultsSpecilties = specialties.sort((a, b) => {
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  })

  const allCountries = ['BR', 'CA', 'DE', 'ES', 'IE', 'IT', 'PT', 'US']

  const getTimes = (start: any, interval: number): string[] => {
    const rangeTime: string[] = []
    const time = '24:00:00'
    const getDate = moment(start).format('YYYY-MM-DD')
    const finalMount = moment.utc(`${getDate}T${time}Z`)
    let initialTime = moment.utc(start).add(interval, 'minutes')
    const finalTime = moment.utc(finalMount)
    while (initialTime <= finalTime) {
      rangeTime.push(moment(initialTime).format('HH:mm'))
      initialTime = moment(initialTime).add(interval, 'minutes')
    }
    return rangeTime
  }

  const handleCreate = async () => {
    let selectedCountries: string[] = []

    if (
      selectedLanguages.includes('Português') &&
      selectedLanguages.includes('Inglês')
    ) {
      selectedCountries = allCountries
    } else if (selectedLanguages.includes('Português')) {
      selectedCountries = ['BR', 'PT']
    } else if (selectedLanguages.includes('Inglês')) {
      selectedCountries = ['CA', 'DE', 'ES', 'IE', 'IT', 'US']
    } else {
      selectedCountries = ['BR']
    }

    const isPsychologist =
      defaultsSpecilties.length === 1 &&
      defaultsSpecilties[0].slug === 'psicologo'
        ? true
        : defaultsSpecilties.find((s) => s.id === newSchedule.areaId)?.slug ===
          'psicologo'

    const dataSchedule = {
      userUuid: newSchedule.userUuid,
      areaId: newSchedule.areaId || defaultsSpecilties[0]?.id,
      start: newSchedule.start,
      end: newSchedule.end,
      plan: attendanceFormat === 'Padrão' ? '' : attendanceFormat,
      languages: selectedLanguages,
      countries: isPsychologist ? selectedCountries : [],

      ...(isPsychologist && { countries: selectedCountries }),
    }

    setLoading(true)
    const response = await createSchedule(dataSchedule)

    if (response.status) {
      setSchedules([...schedules, ...response.schedule])
      setNewSchedule({ start: '', end: '', areaId: '', language: 'Português' })
      setSelectedLanguages([])
      alertSuccess('Agenda criada com sucesso!')
    } else {
      alertWarning(response.msg || 'Erro ao criar agenda')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (info.event.extendedProps.status === 'pendent') {
      setModal(true)
    }
  }, [info.event.extendedProps.status])

  useEffect(() => {
    if (
      newSchedule.areaId &&
      defaultsSpecilties.find((s) => s.id === newSchedule.areaId)?.slug !==
        'psicologo'
    ) {
      setAttendanceFormat('Padrão')
    }
  }, [newSchedule.areaId, defaultsSpecilties])

  const verifyPsico = () =>
    defaultsSpecilties?.find((s) => s.id === newSchedule.areaId)?.slug ===
    'psicologo'

  const handleLanguageChange = (language: string) => {
    setSelectedLanguages((prevLanguages) =>
      prevLanguages.includes(language)
        ? prevLanguages.filter((lang) => lang !== language)
        : [...prevLanguages, language]
    )
  }

  return info.event.extendedProps.status === 'confirmed' ? (
    <OverlayContainer className="text-center" id={`PopoverLegacy-${uuid}`}>
      <OverlayComponent
        onClick={() => {
          setModal(!modal)
          setModalSchedule(!modalSchedule)
        }}
      >
        {specialty}
      </OverlayComponent>
      <UncontrolledPopover
        target={`PopoverLegacy-${uuid}`}
        trigger="legacy"
        placement="auto-start"
        isOpen={modalSchedule}
      >
        <PopoverHeader>
          {`${moment(day).format(
            'dddd, DD/MM'
          )} - Das ${startTime} às ${endTime}`}
        </PopoverHeader>
        <PopoverBody>
          <Button
            id={`PopoverLegacy-${uuid}`}
            type="button"
            onClick={() => handleDelete(uuid)}
          >
            Excluir
          </Button>
        </PopoverBody>
      </UncontrolledPopover>
    </OverlayContainer>
  ) : (
    <OverlayContainer className="text-center" id="agenda">
      <OverlayComponent onClick={() => setModal(!modal)}>
        (Especialidade)
      </OverlayComponent>
      <UncontrolledPopover
        target="agenda"
        placement="auto-start"
        trigger="legacy"
        isOpen={modal}
        defaultShow={info.event.extendedProps.status === 'pendent'}
      >
        <PopoverHeader>Criar Agenda</PopoverHeader>
        <PopoverBody>
          <Form>
            <FormGroup controlId="formBasicEmail">
              <h4>Início às:</h4>
              <Input
                disabled
                value={moment(newSchedule.start).format('HH:mm')}
              />
            </FormGroup>
            <FormGroup controlId="formBasicEmail">
              <h4>Fim às:</h4>
              <Input
                type="select"
                value={newSchedule.end}
                custom
                onChange={(e) => {
                  setNewSchedule({
                    ...newSchedule,
                    end: e.target.value,
                  })
                }}
              >
                {getTimes(
                  moment
                    .utc(info?.event.startStr)
                    .format('YYYY-MM-DD[T]HH:mm:ss'),
                  30
                ).map((time) => (
                  <option
                    key={time}
                    value={`${moment(day).format('YYYY-MM-DD')}T${time}:00`}
                  >
                    {time}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup controlId="formBasicEmail">
              <h4>Área de atuação</h4>
              <Input
                type="select"
                value={newSchedule.areaId}
                onChange={(e) => {
                  setNewSchedule({ ...newSchedule, areaId: e.target.value })
                }}
              >
                {defaultsSpecilties.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            {verifyPsico() && groups.length > 0 && (
              <FormGroup style={{ textAlign: 'center' }}>
                <h4>Formato de Atendimento</h4>
                <Label check className="mr-4">
                  <Input
                    type="radio"
                    name="attendanceFormat"
                    value="Padrão"
                    checked={attendanceFormat === 'Padrão'}
                    onChange={() => {
                      setAttendanceFormat('Padrão')
                    }}
                  />
                  {' Padrão'}
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    name="attendanceFormat"
                    value="Plantão"
                    checked={attendanceFormat === 'Plantão'}
                    onChange={() => {
                      setAttendanceFormat('Plantão')
                    }}
                  />
                  {' Plantão'}
                </Label>
              </FormGroup>
            )}

            {verifyPsico() && (
              <FormGroup controlId="formBasicEmail">
                <h4>Idioma do Atendimento</h4>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Label check className="mr-4">
                    <Input
                      type="checkbox"
                      value="Português"
                      checked={selectedLanguages.includes('Português')}
                      onChange={() => handleLanguageChange('Português')}
                    />
                    {' Português'}
                  </Label>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="Inglês"
                      checked={selectedLanguages.includes('Inglês')}
                      onChange={() => handleLanguageChange('Inglês')}
                    />
                    {' Inglês'}
                  </Label>
                </div>
              </FormGroup>
            )}
          </Form>
          <Button
            type="button"
            onClick={() => {
              handleCreate()
            }}
          >
            Salvar
          </Button>
          <Button
            type="button"
            onClick={() => {
              setModal(!modal)
              setNewSchedule({
                start: '',
                end: '',
                areaId: '',
                language: 'Português',
              })
              setAttendanceFormat('Padrão')
              setSelectedLanguages([])
            }}
            outline
            style={{ margin: 0, marginTop: 10 }}
          >
            Cancelar
          </Button>
        </PopoverBody>
      </UncontrolledPopover>
    </OverlayContainer>
  )
}

export default Overlay
