import React from 'react'
import { Button, Card, CardImg, Col, Row } from 'reactstrap'
import profileImg from 'assets/img/brand/avatar.jpg'
import { useHistory } from 'react-router-dom'

const CardDoctor = ({
  doctorCard,
  schedules,
  schedulingsCanceled,
  schedulingsFinished,
}: any) => {
  const history = useHistory()

  const onClickSchedule = () => {
    history.push(`/admin/agenda/${doctorCard.name}`, { doctorCard })
  }

  const onClickQueries = () => {
    history.push(`/admin/consultas/${doctorCard.name}`, {
      doctorCard,
    })
  }

  return (
    <>
      <Card className="shadow p-3">
        <Row>
          <Col className="d-flex justify-content-center">
            <CardImg
              alt=""
              src={doctorCard?.photo || `${profileImg}`}
              style={{
                borderRadius: '50%',
                marginBottom: '1rem',
                width: '180px',
                height: '180px',
                marginTop: '-90px',
                backgroundSize: 'auto',
              }}
            />
          </Col>
        </Row>

        <Row className="d-flex  justify-content-center p-2 mb-1">
          <Col
            md="4"
            className="d-flex flex-column align-items-center text-center  p-2"
            style={{ fontSize: 14 }}
          >
            <h4 className="d-flex justify-content-center">{schedules || 0}</h4>
            <h6 className=" heading-small text-muted p-0  ">
              Horários Abertos
            </h6>
          </Col>
          <Col
            md="4"
            className="d-flex flex-column align-center  p-2 text-center"
            style={{ fontSize: 14 }}
          >
            <h4 className="d-flex justify-content-center">
              {schedulingsFinished || 0}
            </h4>
            <h6 className="heading-small text-muted  p-0  ">
              Consultas Finalizadas
            </h6>
          </Col>
          <Col
            md="4"
            className="d-flex flex-column align-center  p-2 text-center"
            style={{ fontSize: 14 }}
          >
            <h4 className="d-flex justify-content-center ">
              {schedulingsCanceled || 0}
            </h4>
            <h6 className="heading-small text-muted   p-0 ">
              Consultas Canceladas
            </h6>
          </Col>
        </Row>
        <div
          style={{
            borderBottom: '1px solid #e6e1f1',
            marginBottom: '2rem',
          }}
        >
          {' '}
        </div>
        <Row>
          <Col className="d-flex mb-3">
            <Button
              type="button"
              style={{
                fontSize: 12,
                backgroundColor: '#fff',
                color: '#FF3F72',
              }}
              onClick={() => onClickSchedule()}
            >
              Agenda do Profissional
            </Button>
            <Button
              type="button"
              style={{
                fontSize: 12,
                backgroundColor: '#fff',
                color: '#FF3F72',
              }}
              onClick={() => onClickQueries()}
            >
              {' '}
              Próximas Consultas
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  )
}
export default CardDoctor
