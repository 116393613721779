import React, { useState, useEffect } from 'react'
import ComponentBack from 'components/ComponentBack'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { Card, Col, Container } from 'reactstrap'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import moment from 'moment'
import { deleteSchedule, getSchedule } from 'services/doctor'
import { alertSuccess, alertWarning } from 'utils/toast'
import Loading from 'components/Loading'
import { useLocation } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import Overlay from './components/Overlay'

const ScheduleDoctor = () => {
  const screen = window.innerWidth
  const [loading, setLoading] = useState(false)
  const { state }: any = useLocation()
  const [schedules, setSchedules] = useState([])
  const [specialties, setSpecialties] = useState([])
  const [newSchedule, setNewSchedule] = useState<any>({})

  const getSchedules = async () => {
    setLoading(true)
    const response = await getSchedule(state.doctorCard?.id)
    if (response.status) {
      setSchedules(response.schedule)
      setSpecialties(state.doctorCard?.specialties)
    }
    setLoading(false)
  }

  const filterSchedules = () => {
    const verifyLangs = (sched: any) => {
      const en = sched?.countries?.includes('CA')
      const pt = sched?.countries?.includes('BR')
      const hasCountries =
        sched?.countries?.length > 0
          ? `(${en ? 'inglês' : ''}${pt && en ? ', ' : ''}${
              pt ? 'português' : ''
            })`
          : ''

      return `${sched?.specialty_name} ${hasCountries}`
    }

    if (schedules.length === 0) return []

    return schedules?.map((item: any) => ({
      id: item.id,
      title: verifyLangs(item),
      start: item.start_time,
      end: item.end_time,
      status: 'confirmed',
    }))
  }
  const handleDelete = async (uuid: string) => {
    setLoading(true)
    const res: any = await deleteSchedule(uuid)
    setLoading(false)

    if (res.status) {
      setSchedules(schedules.filter((item: any) => item.id !== uuid))
      alertSuccess('Agenda excluída com sucesso!')
    } else {
      alertWarning(res.msg || 'Erro ao deletar agenda')
    }
  }
  useEffect(() => {
    getSchedules()
  }, [])

  const handleDateClick = (eventInfo: any) => {
    const startTime = moment
      .utc(eventInfo.dateStr)
      .format('YYYY-MM-DD[T]HH:mm:ss')

    const endTime = moment(startTime)
      .add(0.5, 'hours')
      .format('YYYY-MM-DD[T]HH:mm:ss')

    const recentlyCreatedSchedule: any = {
      userUuid: state.doctorCard?.id,
      start: startTime,
      end: endTime.toString(),
      overlap: false,
      areaId: state?.doctorCard?.specialties?.[0]?.id,
      status: 'pendent',
    }
    setNewSchedule(recentlyCreatedSchedule)
  }

  const { data: groups } = useFetch(
    `doctors/ms/v1/user/${state.doctorCard?.id}/groups`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (loading) return <Loading title="Aguarde..." />
  return (
    <>
      <HeaderNeutro />
      <ComponentBack />

      <Container className="m-0 p-0 mt-3" fluid>
        <Card className="shadow ">
          <Col className="m-3">
            <h3>{`Agenda | ${state.doctorCard?.name}`}</h3>
          </Col>

          <div
            style={{
              borderBottom: '1px solid #e6e1f1 ',
            }}
          >
            {' '}
          </div>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView={screen > 768 ? 'timeGridWeek' : 'timeGridDay'}
            locale={ptLocale}
            slotDuration="00:15:00"
            slotLabelInterval="00:15:00"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            events={[...filterSchedules(), newSchedule]}
            slotMinTime="08:00:00"
            slotMaxTime="24:00:00"
            dayHeaderFormat={{
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            }}
            timeZone="America/Sao_Paulo"
            firstDay={1}
            titleFormat={
              screen > 768
                ? { day: 'numeric', month: 'long' }
                : { day: 'numeric', month: 'numeric' }
            }
            eventOverlap={false}
            allDaySlot={false}
            dateClick={(e) => handleDateClick(e)}
            eventContent={(info: any) => (
              <Overlay
                info={info}
                uuid={info.event.id}
                day={info.event.start}
                specialty={info.event.title}
                startTime={moment(info.event.start).utc().format('HH:mm')}
                endTime={moment(info.event.end).utc().format('HH:mm')}
                handleDelete={handleDelete}
                setNewSchedule={setNewSchedule}
                newSchedule={newSchedule}
                setSchedules={setSchedules}
                schedules={schedules}
                specialties={specialties}
                groups={groups?.groups || []}
                setLoading={setLoading}
              />
            )}
          />
        </Card>
      </Container>
    </>
  )
}

export default ScheduleDoctor
