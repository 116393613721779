import React from 'react'
import moment from 'moment'
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  // Button,
} from 'reactstrap'
// import { useHistory } from 'react-router-dom'
import Loading from 'components/Loading'

import useFetch from 'hooks/useFetch'

const getStatus = (status = 'default') => {
  const allStatus: any = {
    active: 'Ativo',
    canceled: 'Cancelado',
    cancelation_requested: 'Cancelamento solicitado',
    blocked: 'Bloqueado',
    default: 'Não informado',
  }
  return allStatus[status]
}

const Plans = ({ userId }: { userId: string }) => {
  // const histoy = useHistory()
  // const onBuscar = (id: string) => {
  //   histoy.push(`/admin/estrela/${id}`, {
  //     id,
  //   })
  // }

  const { data } = useFetch(
    `payments/ms/v1/subscriptions/user/${userId}/all-details`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (data?.length === 0)
    return (
      <Card className="shadow mb-3">
        <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
          <Col className="d-flex align-items-center p-0">Usuário sem plano</Col>
        </CardHeader>
      </Card>
    )
  return (
    <Row>
      {data?.map((plan: any, index: number) => (
        <Col key={String(index)} sm="6">
          <Card className="shadow mb-3">
            {!data && <Loading title="Carregando..." />}
            <CardHeader className="bg-transparent ">
              <Col className="d-flex align-items-center justify-content-between p-0">
                <h2 className="text-black">
                  {`${plan?.title.split(' ')[0]} ${
                    plan?.title?.split(' ')[1] || ''
                  }`}
                </h2>
                {plan?.logo && (
                  <div style={{ width: 40 }}>
                    <img
                      src={plan?.logo}
                      alt="Logo"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                )}
              </Col>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-wrap">
              <ListGroup flush>
                {/* {plan?.company && (
                  <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                    <h4 className="mb-0 text-black">Empresa:</h4>
                    <h4 className="mb-0 text-gray">
                      {plan?.company?.legal_nature}
                    </h4>
                  </ListGroupItem>
                )} */}
                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">Descrição:</h4>
                  <h4 className="mb-0 text-gray">{plan?.title}</h4>
                </ListGroupItem>
                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">Tipo:</h4>
                  <h4 className="mb-0 text-gray">
                    {plan?.is_holder ? 'Titular' : 'Dependete'}
                  </h4>
                </ListGroupItem>
                {plan?.due_date && (
                  <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                    <h4 className="mb-0 text-black">Data de Vencimento:</h4>
                    <h3 className="mb-0 text-gray">
                      {moment(plan?.due_date).format('DD/MM/YYYY')}
                    </h3>
                  </ListGroupItem>
                )}
                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">Status:</h4>
                  <h4 className="mb-0 text-gray">{getStatus(plan?.status)}</h4>
                </ListGroupItem>
                {!!plan.canceled_date && (
                  <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                    <h4 className="mb-0 text-black">Data do Cancelamento:</h4>
                    <h3 className="mb-0 text-gray">
                      {moment(plan?.canceled_date).utc().format('DD/MM/YYYY')}
                    </h3>
                  </ListGroupItem>
                )}
                {!!plan.canceled_reason && (
                  <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                    <h4 className="mb-0 text-black">
                      Descrição do Cancelamento:
                    </h4>
                    <h4 className="mb-0 text-gray">{plan?.canceled_reason}</h4>
                  </ListGroupItem>
                )}

                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">
                    Consultas com Especialistas:
                  </h4>
                  <h3 className="mb-0 text-gray">{`${plan?.appointment_credits} de ${plan?.count_scheduling}`}</h3>
                </ListGroupItem>
                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">
                    Consultas com Nutricionista:
                  </h4>
                  <h3 className="mb-0 text-gray">
                    {`${plan?.appointment_nutri_credits} de ${plan?.number_nutritionist}`}
                  </h3>
                </ListGroupItem>
                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">Consultas com Psicólogo:</h4>
                  <h3 className="mb-0 text-gray">
                    {`${plan?.appointment_psico_credits} de ${plan?.number_psychology}`}
                  </h3>
                </ListGroupItem>
                <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">
                    {`Limite de Consultas Especialistas por ${
                      plan?.description?.includes('Semanal') ? 'semana' : 'mês'
                    }:`}
                  </h4>
                  <h3 className="mb-0 text-gray">
                    {plan?.count_scheduling_month}
                  </h3>
                </ListGroupItem>
                {/* <ListGroupItem className="pr-0 pl-0 d-flex justify-content-between align-items-end">
                  <h4 className="mb-0 text-black">
                    Total de Consultas utilizadas:
                  </h4>
                  <h3 className="mb-0 text-gray">
                    {plan?.consultations_performed}
                  </h3>
                </ListGroupItem> */}
                {/* {plan?.aggregates?.length > 0 && (
                  <ListGroupItem className="pr-0 pl-0 ">
                    <Col className="d-flex align-items-center p-0">
                      <h3 className="mb-0 text-black">
                        {!plan.is_holder ? 'Titular:' : 'Dependentes:'}
                      </h3>
                    </Col>
                    <ListGroup flush>
                      {plan?.aggregates?.map((dependent: any) => (
                        <ListGroupItem
                          key={dependent.id}
                          className="pr-0 pl-0 d-flex justify-content-between align-items-end"
                        >
                          <h4 className="mb-0 text-gray">{dependent.name}</h4>
                          <Button
                            color="primary"
                            size="sm"
                            type="button"
                            outline
                            style={{ width: 50 }}
                            onClick={() => onBuscar(dependent.id)}
                          >
                            Ver
                          </Button>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </ListGroupItem>
                )} */}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
export default Plans
