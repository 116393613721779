import React from 'react'
import { useLocation } from 'react-router-dom'

import { Col, Container, Row } from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'

import useFetch from 'hooks/useFetch'
import { mutate } from 'swr'
import CardDoctor from './components/CardDoctor'
import UseDoctor from './components/UseDoctor'

const Doctor = () => {
  const { state }: any = useLocation()

  const { data, isLoading } = useFetch(
    `doctors/ms/v1/user/${state.params?.id}`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      headers: {
        'accept-language': 'pt-BR',
      },
    }
  )
  const { data: metrics } = useFetch(
    `schedulings/ms/v1/doctor/${state.params?.id}/metrics-appointmets`,
    true,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const doctorUser = async () => {
    mutate(`doctors/ms/v1/user/${state.params?.id}`)
  }

  if (isLoading) return <Loading title="Aguarde..." />
  return (
    <>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="m-0 p-0" fluid>
        <Row className="mt-3 align-items-start d-flex justify-content-between">
          <Col lg="8" className="justify-content-start">
            {data?.id && (
              <UseDoctor doctorData={data} doctorUser={doctorUser} />
            )}
          </Col>
          <Col lg="4">
            {data?.id && (
              <CardDoctor
                doctorCard={data}
                schedules={metrics?.slots}
                schedulingsCanceled={metrics?.canceled}
                schedulingsFinished={metrics?.finished}
              />
            )}
          </Col>
          <Col />
        </Row>
      </Container>
    </>
  )
}

export default Doctor
